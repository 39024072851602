import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"

class RossettLateBusServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Rossett late bus service"
          description="View route information and buy your ticket for the Rossett late bus service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">Rossett late bus</h1>
              <p>Rossett School, Harrogate - York Road, Leeds</p>
              <p>The Rossett late bus is a free service for passengers that hold an annual ticket for one of the main services operated by Tetleys.</p>
              <p>Days of operation are <strong>Wednesday, Thursday and Friday Only</strong>.</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <iframe
                  title="Rossett late bus route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=18BNPIASsTw1JQyJXp2JnHvHxO-edASc&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Rossett School
                </p>
                <p>
                  To Wetherby, East Keswick, Shadwell Terminus, Shadwell Lane, Scott Hall Road/Carr Manor Parade, Oakwoood Roundabout, Aldi York Road
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Wed PM, Thu PM &amp; Fri PM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Rossett School</td>
                      <td>DEP</td>
                      <td>1630</td>
                    </tr>
                    <tr>
                      <td>Aldi, York Road</td>
                      <td>ARR</td>
                      <td>1745</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default RossettLateBusServicePage